import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query PricingPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
        title
        description
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`
const PricingPage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""
  return (
    <Layout className="page">
      <Seo title={frontmatter.title} description={excerpt} image={Image} />
      <div className="no-wrapper">
        <main class="main" role="main">
          <div class="row center-xs hero">
            <div class="col-sm-12 col-lg-9">
              <h1>
                Undebtme es 100%
                <span class="keywords"> GRATIS!. </span>
              </h1>
              <p>
                Para nuestro servicio PREMIUM, el precio lo estableces tú. ¡Sí!
                Un valor justo para ti.
              </p>
            </div>
          </div>
          <div class="row around-sm">
            <div class="col-xs-12 col-sm-4">
              <div class="pricing-container margin-top">
                <p>Gratis</p>
                <h1>$0</h1>
                <p class="grey">Por mes</p>
                <p class="margin-top">1 conexión de cuenta bancaria</p>
                <p class="margin-top">Recompensas limitadas</p>
                <p class="margin-top">Transacciones limitadas</p>
                <p class="margin-top">Descuentos instantáneos limitados</p>
                <p class="margin-top">Soporte estándar</p>

                <Link to="/subscribe">
                  <button>Empezar</button>
                </Link>
                <p class="margin-top">Siempre Gratis</p>
              </div>
            </div>

            <div class="col-xs-12 col-sm-8">
              <div class="pricing-container margin-top">
                <p>Premium</p>
                <h1>Paga lo que es justo. Incluso $0</h1>
                <p class="margin-top">
                  Conexiones a cuentas bancarias ilimitadas
                </p>
                <p class="margin-top">Recompensas ilimitadas</p>
                <p class="margin-top">Transacciones ilimitadas</p>
                <p class="margin-top">Descuentos instantáneos ilimitados</p>
                <p class="margin-top">Soporte Premium</p>
                <Link to="/subscribe">
                  <button>Comenzar Ahora</button>
                </Link>
                <p class="margin-top">Pago lo que es justo para ti.</p>
              </div>
            </div>
          </div>

          <div class="row center-xs">
            <div class="col-xs-12 col-sm-12">
              <h3>¿No ves tu plan aquí?</h3>
              <p>
                Si necesitas un plan más personalizado,{" "}
                <a href="mailto:holaundebtme@gmail.com">
                  comunícate con Roberto Hernandez
                </a>{" "}
                para ofrecerte algo de acuerdo a tus necesidades.
              </p>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default PricingPage
